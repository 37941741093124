.benfittsDescriptionv1 {
  @apply flex flex-col text-left text-xs;

  p {
    @apply pb-2 text-[14px]/relaxed;
  }

  p:first-of-type {
    padding-top: 12px;
    border-top: 1px solid #cecece;
  }

  p:last-of-type {
    margin-bottom: 8px;
  }
}

.benefits {
  @apply flex flex-col text-left text-xs;

  ul {
    @apply space-y-3;
  }

  ul li {
    background-image: url('data:image/svg+xml,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20width=%2225%22%20height=%2225%22%20viewBox=%220%200%2025%2025%22%20fill=%22none%22%3E%3Cpath%20d=%22M6%2013.4851L11.358%2018.0938L19.3648%208%22%20stroke=%22%231F1E1D%22%20stroke-width=%222%22%20stroke-miterlimit=%2210%22/%3E%3C/svg%3E');

    @apply bg-[length:25px_21px] md:bg-[length:25px_25px];

    background-position: left 50%;
    background-repeat: no-repeat;
    margin-bottom: 8px;
    padding-left: 24px;

    p {
      @apply text-[14px];

      padding-left: 8px;
      line-height: 100%;
    }
  }
}

.additionalOffer {
  @apply text-xs text-left;

  strong {
    @apply text-purple font-bold;

    font-size: 14px;
    line-height: 18.2px;
  }
}

.swiperPagination {
  @apply absolute z-50 flex justify-center space-x-4 items-center w-full bottom-0 left-0;
}

.swiperPaginationTooFewSlides {
  @media screen and (width >=840px) {
    display: none;
  }
}

.swiperPaginationBullet {
  @apply inline-block bg-violet rounded-full;

  height: 12px;
  width: 12px;
}

.activeSwiperPaginationBullet {
  @apply bg-purple;
}

.planCard {
  @apply w-[320px] sm:w-[240px] lg:w-[320px] flex flex-col relative rounded-3xl text-xs m-0;
}

.planContainer {
  @apply p-6 md:py-13 md:px-8 rounded-3xl text-xs bg-[#F4F0E7] z-10 md:h-full;
}

.mostPopularBadge {
  @apply block absolute top-[-76px] right-0 left-[-4px] mx-auto font-bold text-center text-[16px] lg:text-[18px] leading-[34px] bg-[#8652FF] text-white;

  width: calc(100% + 8px);
  border-radius: 24px;
  padding-top: 22px;
  height: calc(100% + 80px);
}

.badge {
  @apply w-[146px] h-[34px] block absolute top-[-17px] right-0 left-0 z-20 mx-auto rounded-full font-bold text-center text-sm leading-[34px] bg-[#8652FF] text-white;
}

.heading {
  @apply text-lg md:text-[24px] font-bold mb-2 leading-[110%] tracking-[-0.84px] md:tracking-[-1.44px];
}

.price {
  @apply text-[#1E1F1D] text-[18px] leading-[125%] md:leading-[30px] tracking-[-0.18px];
}

.offerContainer {
  @apply flex flex-row items-center mt-6 mb-3 bg-white rounded-md;
}

.planToggle {
  @apply relative flex flex-row justify-between min-w-[220px] h-[44px] rounded-full cursor-pointer items-center bg-violet mb-6 px-1 text-black text-base leading-7;
}

.toggleInput {
  @apply hidden;
}

.toggleOption {
  @apply py-1 px-6 h-[36px] rounded-full transition-all;
}

.toggleOptionMonthly {
  @apply peer-[:not(:checked)]:bg-[--dark-purple] peer-[:not(:checked)]:text-white peer-checked:text-black;
}

.toggleOptionYearly {
  @apply peer-checked:bg-[--dark-purple] peer-checked:text-white peer-[:not(:checked)]:text-black;
}
